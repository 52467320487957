const ContactForm = () => {
  return (
    

    <div className="container">
            <h1>Contact Fort Worth Doodle Co</h1>
            
            <div className="contact-box">                
                <form  action="https://formsubmit.co/fwdoodleco@gmail.com" method="POST" className="form">
                    <input type="text"className="field" placeholder="First Name" name="firstName" id="firstName" required/>
                    <input type="text"className="field" placeholder="Last Name" name="lastName"id="lastName" />
                    <input type="email"className="field" placeholder="Email" name="email" id="email" required/>
                    <input type="text"className="field" placeholder="Phone Number" name="phone" id="phone"/>
                    
                                   
                    
                    <input type="text" className="field" name="subject" id="subject" placeholder="Email Subject"/>
                   <textarea className="field" name="message" id="message" rows="3" cols="30" placeholder="Type your message here."></textarea>
                    <button className="btn" type="submit" onClick="validateForm()">Send</button>
                    <input type="hidden" name="_next" value="https://fortworthdoodleco.com/#/contact-us"/>
                    <input type="hidden" name="_captcha" value="false"/>
                </form>
            </div>
            <div className="padding">
                <div className="map-responsive">
                   
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d312477.27132140484!2d-97.00711535694128!3d32.78867754898876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1644165197750!5m2!1sen!2sus" 
                    width="400" height="300" frameBorder="0" allowFullScreen></iframe>
                </div>
            </div>
      </div>
   

  )
}
export default ContactForm