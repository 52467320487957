import React from 'react'
import styled from 'styled-components'

const GuardianHomes = () => {
  return (       <Wrapper>
    <div>
      <h1>Guardian Home</h1>

      <h3>Why? </h3>
      <p>
        At Fort Worth Doodle Company, we are committed to doing things differently - this starts with the care and treatment of our adult dogs!  
        When we decided to begin raising doodles, we made a commitment that we wanted our dogs to always be pets first and foremost.  
        We wanted them to live as members of the family and receive 100% of the love and attention they deserve each and every day.  
        It is absolutely crucial for their happiness, healthiness, socialization and development.  In any situation, there is a limit to how much time and 
        attention any one person can give a dog. While some dog breeds are able to thrive outdoors or in a kennel environment, that is not the case for doodles and poodles- 
        they crave companionship and are happiest when they are surrounded by their people.  Our solution?  Guardian Homes.
      </p>

      <h3>What is it? </h3>
      <p>
        A Guardian Home is a carefully selected family who adopts a special dog that embodies the best traits of the breed we can find.  
        These dogs typically come from champion backgrounds, have extensive pedigree tracing, and are brought in from around the country.  
        This is a mutually beneficial relationship for a family that wants an amazing dog and the dog.   The dog joins the family as a puppy, visits Fort Worth Doodle 
        Company to raise up to 6 litters of puppies, then remains with the Guardian Family for its entire life as their family member.  
        This relationship allows every Fort Worth Doodle to have the family, love and attention it deserves.
      </p>

      <h3>I’m Interested!</h3> 
      <p>
        If you are interested in more information on becoming a Guardian Home for a Fort Worth Doodle Company dog, please email us or reach out through social media. 
        Please know that our guardian families are carefully selected on a number of factors. We place our dogs and puppies with great care and consideration.
      </p>
    </div>     
  </Wrapper>
  )
}

const Wrapper = styled.main`
div {
    max-width: 800px;
    width: 85%;
    margin: 0 auto;
    line-height: 1;
    /* padding: 60px 0; */
  }
  h1 {
  font-size: 40px;
  font-weight: 500;
  font-variant: small-caps;
  margin: 20px auto;
  text-align: center;
  }
`
export default GuardianHomes