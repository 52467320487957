
  
      const ImageDataFemale = [
        {id: 0, src: '/images/Dancer6w.jpg',title: 'Dancer puppy',},
        {id: 1, src: '/images/Cupid6w.jpg',title: 'Cupid puppy',},
        {id: 2, src: '/images/Donder6w.jpg',title: 'Donder puppy',},
        {id: 3, src: '/images/Frost6w.jpg',title: 'Dancer puppy',},
        {id: 4, src: '/images/Blitzen6w.jpg',title: 'Blitzen puppy',},
        {id: 5, src: '/images/Vixen6w.jpg',title: 'Vixen puppy',}, 
      ]


 
  export default ImageDataFemale