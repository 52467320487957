import React, {useState} from 'react'
import styled from 'styled-components'



const ImageSlider = ({slides}) => {
   const [currentSlide, setCurrentSlide] = useState(0);

   const moveNext = () => {
    const isLastSlide = currentSlide === slides.length-1
    const newIndex = isLastSlide ? 0 : currentSlide + 1;
    setCurrentSlide(newIndex)
   }
   const movePrev = () => {
    const isFirst = currentSlide === 0
    const newIndex = isFirst ? slides.length -1 : currentSlide -1;
    setCurrentSlide(newIndex);
  }
  const goToSlide = (slideId) => {
    setCurrentSlide(slideId);
  }
 
  const slideStyles =  {
   
    
    borderRadius: '10px',
  
  }
  const sliderStyles = {
   position: 'relative'
  }
  const rightArrowStyles ={
    position: 'absolute',
    top: '50%',
    transform: 'translate(0,-50%)',
    right: '18px',
    color: 'white',
    fontSize: '45px',
    cursor: 'pointer',
    zIndex: 1,
    }
  const leftArrowStyles = {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0,-50%)',
    left: '18px',
    color: 'white',
    fontSize: '45px',
   
  }
  const dotsContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '10px',
  }
  const dotStyle = {
    margin: '0 3px',
    cursor: 'pointer',
    fontSize: '20px',
  }
  const grayDot = {
   
      height: '15px',
      width: '15px',
      backgroundColor: '#bbb',
      borderRadius: '50%',
      display: 'inline-block',
      margin: '15px 3px 10px 3px',
      cursor: 'pointer',
      
    
  }
  const blackDot = {
   
    height: '15px',
    width: '15px',
    backgroundColor: '#000000',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '15px 3px 10px 3px',
    cursor: 'pointer',  
}
  return (
    <Wrapper>
    <div style = {sliderStyles}>
      <div onClick={moveNext} style={rightArrowStyles}>❱</div>
      <div onClick={movePrev} style={leftArrowStyles}>❰</div>
      <div 
      // {/*style={slideStyles} */}
      >
          <img 
          style={{width: '100%'}} 
          src ={slides[currentSlide].src} 
          alt ={slides[currentSlide].title}
          
          /> 
      </div>
      <div style ={dotsContainerStyle}>
        {slides.map((slide) => {
          return (
          <div style = {dotStyle} onClick = {() => goToSlide(slide.id)} key={slide.id}>
            {slide.id === currentSlide ? <span style = {blackDot}></span>:<span style = {grayDot}></span>}
          </div>
          )
        })}
      </div>
    </div>

  </Wrapper>

  )

}

const Wrapper = styled.main `
.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

`
export default ImageSlider