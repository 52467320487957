
import {BrowserRouter, HashRouter, Routes, Route } from 'react-router-dom'
import SharedLayout from './pages/SharedLayout'
import AvailablePuppies from '../src/pages/AvailablePuppies'
import PreviousLitters from './pages/PreviousLitters'
import Faq from './pages/Faq'
import Home from './pages/Home';
import ContactForm from './pages/ContactForm';
import Doodle101 from './pages/Doodle101';
import Header from './pages/Header';
import Footer from './pages/Footer';
import GuardianHomes from './pages/GuardianHomes'





function App() {
  return (
   
  <HashRouter basename='/'> 
    <Header />
    
    <Routes>
    
    <Route path='/' element={<Home />} />
        <Route path='available-puppies' element={<AvailablePuppies />} />
        <Route path='previous-litters' element={<PreviousLitters/>} />
        <Route path='guardianHomes' element={<GuardianHomes/>} />
        <Route path='doodle101' element={<Doodle101/>}/>
        <Route path='contact-us' element={<ContactForm/>}/>
        <Route path='faq' element={<Faq/>}/>
        

    </Routes>
    <Footer />
    
  </HashRouter>
  )
}

export default App;
