import styled from 'styled-components'
import React from 'react'
import ImageSlider from '../components/ImageSlider'

//Pics import

import ImageDataFemale from '../components/ImageDataFemale'
import ImageDataMale from '../components/ImageDataMale'
import Prancer from  '../images/Prancer.jpg'

/* import Dancer from '../images/Dancer.jpg'
import Prancer from  '../images/Prancer.jpg'
import Cupid from  '../images/Cupid.jpg'
import Comet from  '../images/Comet.jpg'
import Dasher from  '../images/Dasher.jpg'
import Donder from  '../images/Donder.jpg'
import Rudolph from  '../images/Rudolph.jpg'
import Vixen from  '../images/Vixen.jpg'
import Frost from  '../images/Frost.jpg'
import Blitzen from  '../images/Blitzen.jpg'
import Buddy from  '../images/Buddy.jpg'
import Kringle from  '../images/Kringle.jpg'  */

const AvailablePuppies = () => {
    
  return (
      <Wrapper >
     
    <section>
        <div className="FAQIntro">
            <h1>Available Puppies</h1>
        </div>
        
    </section>
    <section className="dogsdesktop">
      
   
    <div className="FAQ">
    <h3>F1b Standard English Goldendoodles</h3>
    <ul className='noBullets'>
      <li>Dam: Claire (AKC Poodle)</li>
      <li>Sire: Benson (F1 English Cream Goldendoodle)</li>
    </ul>
    <p></p>
    <ul className='noBullets'>
      <li>Birth Date - November 2, 2022</li>
      <li>"Pick" Date - November 30, 2022</li>
      <li>Go Home Date - December 28, 2022</li>
    </ul>
   
    <ul className='noBullets'>
    <li>🎅🏻 Santa’s Sleigh Litter of 12 🛷 </li>
    <li>🦌 6 females - 0 remaining</li>
    <li>🦌 6 males - 3 remaining</li>
    </ul>
    <p>Reserve list <span className='open-bold'>Open</span></p>
    {/* <ul>
        <li>1st Pick: Fort Worth Doodle Company</li>
        <li>2nd Pick: Roub Family</li>
        <li>3rd Pick: SimpsonFamily</li>
        <li>4th Pick: Sorrell Family </li>
        <li>5th Pick: *Open*</li>
        <li>6-12th Pick: *Open*</li>    
    </ul> */}
    <p>If you want your puppy just in time to put under the Christmas tree, we would be thrilled to accommodate! If you would like to pick up your puppy on December 23 or 24 please just let us know!</p>

<p>*Reserve deposits are non-refundable but are transferable to future litters. Specific breeds, sizes, and colors are not guaranteed in future litters.</p>
    
    {/* <p>We allow up to 7 reserves before the puppies are born to ensure every family gets a puppy. Fort Worth Doodle Company reserves the right to first pick on every litter. Should FWDC decide not to keep a puppy from the litter, we reserve the right to gift our first pick to another family. In the event neither opportunity presents itself, FWDC will forfeit its right to pick of the litter and the reserve list will continue in order.</p> */}
    </div>
    {/*  <div className='picContainer2'>
      <div className='item'>
      <img className='images' src={Dancer} alt='Dancer puppy'/>
      </div>
      <div className='item'>
      <img className='images' src={Comet} alt='Comet puppy'/>
      </div>
      <div className='item'>
      <img className='images' src={Prancer} alt='Prancer puppy'/>
      </div>
      <div className='item'>
      <img className='images' src={Cupid} alt='Cupid puppy'/>
      </div>
      <div className='item'>
      <img className='images' src={Frost} alt='Frost puppy'/>
      </div>
      <div className='item'>
      <img className='images' src={Dasher} alt='Dasher puppy'/>
      </div>
      <div className='item'>
      <img className='images' src={Kringle} alt='Kringle puppy'/>
      </div>
      <div className='item'>
      <img className='images' src={Buddy} alt='Buddy puppy'/>
      </div>
      <div className='item'>▶
      <img className='images' src={Blitzen} alt='Blitzen puppy'/>
      </div>
      <div className='item'>
      <img className='images' src={Vixen} alt='Vixen puppy'/>
      </div>
      <div className='item'>
      <img className='images' src={Rudolph} alt='Rudolph puppy'/>
      </div>images
      <div className='item'>
      <img className='images' src={Donder} alt='Frost puppy'/>
      </div>
    </div> */}

    <div className="Anything-further">
      <div className='sliderContainer'>
        <div className='imageSlider'>
          <h2>Females</h2>
          <ImageSlider slides={ImageDataFemale} />
        </div>
        <div className='imageSlider'>
        <h2>Males</h2>
        <ImageSlider slides={ImageDataMale} />
        </div>
      </div>
      <h5>To be placed on the reserve list, please click the buy now paypal button below.</h5>
      
    

      <div className='paypal'>
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick"/>
          <input type="hidden" name="hosted_button_id" value="JYZ7UHPMEK3BE"/>
          <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
          <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
        </form>
      </div>
      <h5>For for any further questions, please reach out to us at <a href="mailto:fwdoodleco@gmail.com">fwdoodleco@gmail.com</a>. </h5>
    </div>

  
    
</section>

</Wrapper>
  )
}

const Wrapper = styled.main`
.sliderContainer {
 display: flex;
 width: 100%;
  
.imageSlider {
  width: 100%;
  padding: 10px;
}
  
}
.noBullets, p {
  list-style-type:none;
  padding-left: 0;
}
.paypal {
  display: flex;
  align-items:center;
  
  justify-content:center;
}
  .dogsdesktop{
    position: relative;
    /* margin: auto; */
    overflow: hidden;
    padding-bottom: 8px;
  }
  .dogsmobile{
    padding-bottom:8px;
  }
  .dogimagecontainer img {
    padding: 2px 0;
  }
  .DoodleItem img {
    float: left;
    margin-right: 20px;
    max-width: 300px;
  }
  .dogimagecontainer {
    position: relative;
    text-align: center;
    color: white;
  }
  .bottom {
    position: absolute;
    bottom: 8px;
  }
  .dogrow{
    padding: 10px;
  }
  .open-bold{
    font-weight:bold;
  }

  /* Create padding between columns */
  .dogrow > .dogcolumn {
    padding: 0 4px;
  }

  /* Caption text */
  .dogtext {
    color: #f2f2f2;
    font-size: 15px;



    /* width: 100%; */
    /* text-align: center; */
  }
  .dogimagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.dogcolumn {
  float: left;
  width: 50%;
}
.bottom {
  position: absolute;
  bottom: 8px;
  left: 40%;
}
.litter-info {
  border-bottom: 3px solid #105834;
}
.dog-parents-pic-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

}
.henry {
  margin-right: 10px;
  
}
.picContainer1 {
  column-count:2;
  column-gap: 8px;
}
.picContainer2 {
column-count:4;
column-gap: 2px;
}
.item { /* Masonry bricks or child elements */
    background-color: #eee;
    display: inline-block;
    margin:  2px;
   
  }
  .images {
    width:  100%;
  }
  .available {
    border: 3px solid #105834;
  }
/* responsive */
  .pic-container1, .pic-container2 {
      column-count: 1;
    }

@media screen and (max-width:800px){
  .paypal input {
    max-width: 320px;
  }
  .picContainer1, .picContainer2 {
      column-count: 1;
    }
    .dog-parents-pic-container {
      display: block;
     
    }
    .images {
      /* width: 100%; */
      width:  100%;
      
    }
    
.sliderContainer {
  display: block;
  max-width: 368px;
  padding-bottom: 30px;
}
  
  .imageSlider {
    
    height:400px;
    margin: 50px auto 140px auto;
    width:100%;
}
  
}
 
 
  `
export default AvailablePuppies;