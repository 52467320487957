const Footer = () => {
  return (
    

        <footer>
            <div className="footer-content">
                <h3>Fort Worth Doodle Co.</h3>
                {/* <!-- <p>Catch Phrase</p> --> */}
                <ul className="socials">
                    <li><a href="https://www.facebook.com/FWDoodleCo" target="_blank"><i className="fa fa-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/fwdoodleco/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                </ul>
            </div>
            <div className="footer-bottom">
                <p>Copyright &copy;2022 Fort Worth Doodle Company.  All Rights Reserved.</p>
            </div>
        </footer>
   
)
   
}
export default Footer