const ImageDataMale = [
       
    {id: 0, src: '/images/Prancer6w.jpg',title: 'Prancer puppy',},
    {id: 1, src: '/images/Comet6w.jpg',title: 'Comet puppy',},
    {id: 2, src: '/images/Dasher6w.jpg',title: 'Dasher puppy',},
    {id: 3, src: '/images/Rudolph6w.jpg',title: 'Rudolph puppy',},
    {id: 4, src: '/images/Buddy6w.jpg',title: 'Buddy puppy',},
    {id: 5, src: '/images/Kringle6w.jpg',title: 'Kringle puppy',},
  ]

   export default ImageDataMale