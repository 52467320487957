import smallLogo from '../images/logo_small_trimmed.jpg'
import dogGroupPic from '../images/All3dogshomejpeg.jpg'


const Home = () => {
  return (
    <main>
            
        {/* <!-- ********************  BUSINESS INTRO  ********************* --> */}

            <section className="business-intro">
                    <div className="top-logo">
                        <img src={smallLogo} alt="Fort Worth Doodle Company logo; two doodles looking happy and ready to meet you"/>
                    </div>
                    <h1>Fort Worth Doodle Company</h1>
                    <div>
                        <img src={dogGroupPic} alt="A white party poodle, a sheepadoodle and an english cream golden doodle are sitting happy in the sun"/>
                    </div>
                <div className="content-wrap">
                    <h2>Hello from COWTOWN TEXAS!</h2>
                    <br />
                    <p><b id="Emphesize">Welcome to Fort Worth Doodle Company!</b> We are a northern Texas family that raises standard size Sheepadoodles and English Cream Goldendoodles. Our puppies are a part of our family, and they enjoy playtime with our kids (puppy trainers) and exploring the world around them. Our kids take part in day-to-day handling of the dogs and puppies, which ensures that you get a puppy that is used to a lot of love and attention!</p>
                    <p>Our parent dogs are health and genetic tested and cleared before breeding. We are happy to provide these results upon request. We strive to raise well-socialized pups that are both happy and healthy. Our puppies are kept with their momma for 8 weeks to ensure they get the love, nutrition, and proper start to life that they deserve.</p>
                    <p>Let us help you in selecting your <i>fur-ever</i> family member with one of our sweet doodles! Take a look around, browse our FAQs and feel free to reach out via text, call, or email with any questions. We LOVE talking about our doodles and want the best match for everyone!</p>
                </div>
            </section>
            
        </main>
  )
}
export default Home
