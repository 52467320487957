import { NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import smallLogo from "../images/logo_small_transparent.png"

const StyledNavbar = () => {
   const [click, setClick] = useState(false);
   const handleClick = () => setClick(!click);
   const setClickFalse = () => setClick(false);
 
  return (
     <Wrapper>
     <nav >
         <div className="mobileNavContainer">
            
          <NavLink onClick={setClickFalse} className={({isActive}) => isActive?'active mobile-logo':'mobile-logo'} to='/'><img src={smallLogo} alt="Fort Worth Doodle Company logo; two doodles looking happy and ready to meet you"/></NavLink>
            <div className="nav-icon" onClick={handleClick}><i className={click? "fas fa-times":"fas fa-bars"}></i></div>
     
        </div>
        
        
        <ul className={click? "topnav show":"topnav"}>
         <li className='nav-item'  >
            <NavLink className={({isActive}) => isActive?'active':''} to="/" onClick={handleClick}>Home</NavLink>
         </li>
         <li className="nav-item">
            <NavLink className={({isActive}) => isActive?'active':''} to="/doodle101" onClick={handleClick}>Doodle 101</NavLink>
         </li>
         <li className="nav-item">
            <NavLink className={({isActive}) => isActive?'active':''} to="/available-puppies" onClick={handleClick}>Available Puppies</NavLink>
         </li>
         <li className="nav-item">
            <NavLink className={({isActive}) => isActive?'active':''} to="/previous-litters" onClick={handleClick}>Previous Litters</NavLink>
         </li>
         <li className="nav-item">
           <NavLink className={({isActive}) => isActive?'active':''} to="/guardianHomes" onClick={handleClick}>Guardian Homes</NavLink> 
         </li>
         <li className="nav-item">
            <NavLink className={({isActive}) => isActive?'active':''} to="/faq" onClick={handleClick}>FAQ</NavLink>
         </li>
         <li className="nav-item">
        <   NavLink className={({isActive}) => isActive?'active':''} to='/contact-us' onClick={handleClick}>Contact Us</NavLink>
         </li>
        </ul>
        
     </nav>
    
     </Wrapper>
  )
}
const Wrapper = styled.main`
nav {
   
}

  .topnav {
    font-family: 'Barlow', sans-serif;
    font-weight: 200;
    background-color: #333;
    overflow: hidden;
    display: flex;
    list-style-type: none;
    justify-content: center;
    vertical-align: middle;
    flex-wrap: wrap;
    margin: 0;
  }
 
  
  /* Add a color to the active/current link */
  .active {
    background-color: #540B0E;
    color: #f2f2f2;
    pointer-events: none;
  }
  /* Set spacing for menu items */
  .nav-item{
    padding-right: 1em;
    padding-left: 1em;
    
  }
    
  .mobileNavContainer {
  display:none;
  }
    
   .mobile-logo img{
   max-width: 65px;
  margin: 10px;
   display: flex;
   
  }
  

  
  @media screen and (max-width:820px){
   .topnav {
    font-family: 'Barlow', sans-serif;
    background-color: #333;
   
    /* overflow: hidden; */
    display: none;
    list-style-type: none;
    justify-content: center;
    vertical-align: middle;
    flex-wrap: wrap;
    flex-direction:column;
    margin: 0;
    width:100%;
    left:0px;
  }
  .show {
     display:flex
  }
 
  .mobileNavContainer {
     background: #540B0E;
      display:flex;
      justify-content:space-between;
    
    
  }
   .nav-icon {
      display:flex;
      background: #540B0E;
      margin:  auto;
      transition: 0.3s ease all;
      color:white;
      margin: 30px 15px;
     
     
   }
   
  }
`

export default StyledNavbar