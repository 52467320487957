import styled  from "styled-components";
import puppyCollage from '../images/puppyCollage.jpg';


const PreviousLitters = () => {
  return ( <Wrapper>
    <div>

  <h1>Previous Litters</h1>

<h3>Spring ‘22 Cookie Litter</h3>
<h5>Parents: Claire (AKC Poodle) and Henry (F1b sheepadoodle)</h5>

  <p>9 Standard F1bb Sheepadoodles</p>
<img src={puppyCollage} alt='2022 Cookie Litter puppy collage' />
    
    </div>

      </Wrapper>
      
  )
}

const Wrapper = styled.main`

div {
    max-width: 800px;
    width: 85%;
    margin: 0 auto;
    line-height: 1;
    /* padding: 60px 0; */
  }
  h1 {
  font-size: 40px;
  font-weight: 500;
  font-variant: small-caps;
  margin: 20px auto;
  text-align: center;
  }
  img {
    margin-bottom: 18px;
  }
  @media screen and (max-width:820px){
    img {
      width:100%;
    }
  }
`
export default PreviousLitters
