import henryFunSmallPic from '../images/Henry_fun_small.jpg'
import bennyLayPic from "../images/Benny_lay_small.jpg"
import styled from 'styled-components'

const Doodle101 = () => {
  return (
    <Wrapper>
              <div className="business-intro"> 
                <h1>Doodle 101</h1>
                <h3>Doodle What?</h3>
                <p>There are many types of doodles being bred today. A <i>doodle</i> is essentially a dog bred using a pure Poodle and any other breed. 
                This brings a coat of hair that sheds less or not at all. It also combines the personality traits and temperaments of both the Poodle and the 
                complimenting breed.  The resulting new breed brings about the types of -doodles that people have come to know and love. </p>
                <h3>Our Doodles</h3>
                <p>At Fort Worth Doodle Company, we currently raise two types of doodles:</p>
                    <section className="DoodleItem">
                      <h4>F1bb Standard Sheepadoodle</h4>
                        <img className='henry1' src={henryFunSmallPic} alt="Black and white sheepadoodle next to a pond, happy with his tounge hanging out"/>
                        <p> A sheepadoodle is a cross between a pure Poodle and an Old English Sheepdog. The resulting doodle is an intelligent, loving, 
                          protective, and energetic companion who will always keep you entertained. The sheepdog in their bloodline causes this type of doodle 
                          to be very family oriented as it has a herding mentality in its blood. Our Sheepadoodle puppies are considered F1bb because they are 
                          a cross between an F1b Sheepadoodle and a pure Poodle. This results in a wavy to curly coat of hair that is unlikely to shed at all. 
                          These sheepadoodles will be 45 to 70 pounds full grown. The puppies are black and white, often with white chest pieces and white snouts. 
                          Each sheepadoodle puppy will have unique markings that change as they mature. Some Sheepadoodles fade to silver as they age, keeping with 
                          the Old English Sheepdog tradition. Our father, Henry is currently black with some fading beginning on his back at 2 years old.</p>
                    </section>
                    <section className="DoodleItem">
                        <h4>F1b Standard English Goldendoodle</h4>
                        <img src={bennyLayPic } alt="Black and white sheepadoodle next to a pond, happy with his tounge hanging out"/>
                        <p>The Goldendoodle is a cross between a pure Poodle and an English Golden Retriever. This specific type of retriever has a blockier, 
                          more muscular build than the American retriever. The resulting doodle is an intelligent, loving, intuitive and friendly companion 
                          who will always be by your side. Our Goldendoodle puppies are considered F1b because they are a cross between an F1 English 
                          Goldendoodle and a pure Poodle. This results in a wavy coat of hair that will shed very little to none. These English Goldendoodles 
                          will be 45 to 65 pounds full grown. The puppies will be apricot or cream with a kissable black nose.</p>
                          </section>
                <h3>Choosing the Right Doodle</h3>
                <p>Adding a puppy to your family is an exciting time for you and the puppy. We so desire for our puppies to be loved and adored in every home 
                  they go to. Choosing between a sheepadoodle and goldendoodle can seem like a big task. We encourage you to read more articles about their 
                  personalities and irresistible quirks. You can also talk to us about the type of dog and activities your family is involved in so we can 
                  help you make the best decision in your fur-ever companion. We put great care into choosing our parents and are confident you will bring 
                  home a doodle who will fill your heart and home with joy, laughter, and fluffy cuddles.</p>
              </div>
            </Wrapper>
  )
}

const Wrapper = styled.section ` 
 @media screen and (max-width:809px){
.henry1 {
  margin-right: 50px;
}
 }
`
export default Doodle101;