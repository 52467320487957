import {Link} from 'react-router-dom'

const Faq = () => {
  return (
  

    <section>
                <div className="FAQ-Intro">
                    <h1>Frequently Asked Questions</h1>
                </div>
                <div className="FAQ">
                  <h3>Reserve List</h3>
                  <p>We want to devote our time and energy to our committed puppy parents. Accepting a reserve deposit allows us to reserve a puppy especially for you! Your reserve deposit is non-refundable but it is transferrable to another litter. The reserve deposit secures your place on selection day!</p>
                  <h3>Selection Day!</h3>
                  <p>Selection day will take place when the pups are 4-5 weeks old. A video of each pup will be posted on our FB and IG pages. Selection will be in order of reserve deposits received and go down the list in order. There will be time for us to answer questions you may have about color, markings, and size.</p>
                  <h3>Updates</h3>
                  <p>Once the puppies are here, we will post an update on FB and IG regarding how many pups were born. Please do not request additional pictures at that time. Our schedule revolves around caring for the pups and their momma.</p>
                  <p>You will receive weekly pictures and sporadic videos of the pups as they grow and change. This is an exciting time for everyone!</p>
                  <h3>Vaccinations</h3>
                  <p>All pups receive Bordetella and DAPPv included in the price of your pup. We will also deworm every two weeks to ensure your puppy arrives healthy. Your puppy will also arrive with a microchip in place that will need to be registered with your information.</p>
                  <h3>Delivery Day</h3>
                  <p>We will deliver our pups to predetermined locations around the Dallas-Fort Worth metroplex area. Delivery trip details will be discussed around selection day. Further delivery can be discussed on a case by case basis.</p>
                  <h3>Puppy Food / Pack</h3>
                  <p>Your puppy will grow and develop best with high quality food. We will feed our dogs and puppies <a href ="https://www.chewy.com/hills-science-diet-puppy-chicken-meal/dp/37837" target="_blank">Hill's Science Diet</a> dry kibble. Hill's Science suggests that puppies stay on this food until the age of 12 months. This food is currently available through chewy.com with auto-ship options as well.</p>
                  <p>Your puppy pack will include 4 cups of Hill's Science dry puppy food, a collar, blanket/lovey with momma’s scent, a toy, training treats, and all paperwork for immunizations and microchip.</p>
                  <h3>Health Guarantee</h3>
                  <p>We desire for you to feel confident bringing your furever friend home. All of our puppies come with a 1 year health guarantee. You will receive a puppy contract that details this guarantee and provides you peace of mind. If you'd like a copy of this guarantee, please feel free to request it by email at any time!</p>
                </div>
                <div className="Anything-further">
                  <p>For any further questions, please feel free to reach out to us at <a href="mailto:fwdoodleco@gmail.com">fwdoodleco@gmail.com</a>. </p>
                </div>
    </section>
 
    
  )
}
export default Faq