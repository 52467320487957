
import styled from 'styled-components';
import StyledNavbar from '../components/StyledNavbar';



const Header = () => {
  return (<>
    <Wrapper className="contact-header">
      
      <a href="tel:701-318-3429">701-318-3429</a>
      <a href="mailto:fwdoodleco@gmail.com">fwdoodleco@gmail.com</a>
      <StyledNavbar />
    </Wrapper >

  </>
  )
}
export default Header

const Wrapper = styled.section`
.mobile-logo{
  max-width: 65px;
  display: none;
}
@media screen and (max-width:820px){
  .mobile-logo {
    display:block;
  }
 }

`
